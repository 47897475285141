@font-face {
    font-family: 'W95FA';
    src: local('W95FA'), url(./fonts/W95FA.otf) format('opentype');
}

@font-face {
    font-family: 'alarm';
    src: local('alarm clock'), url(./fonts/alarm\ clock.ttf) format('truetype');
}

@font-face {
    font-family: 'pixel';
    src: local('dogicapixel'), url(./fonts/dogicapixel.ttf) format('truetype');
}

@font-face {
    font-family: 'lightcursive';
    src: local('mathilde'), url(./fonts/mathilde.otf) format('opentype');
}

@font-face {
    font-family: 'lcd';
    src: local('LCD14'), url(./fonts/LCD14.otf) format('opentype');
}

@font-face {
    font-family: 'alfredo';
    src: local('ALFREDO_'), url(./fonts/ALFREDO_.ttf) format('truetype');
}

@font-face {
    font-family: 'signature';
    src: local('Arty Signature'), url('./fonts/Arty\ Signature.otf') format('opentype');
}

p, h1, h2, h3, h4, h5, a {
    color: white;
    font-family: W95FA;
}

textarea {
    color: black;
    font-family: W95FA;
}

.app-icon {
    display: flex; 
    flex-direction: column; 
    align-items: center;
    height: 86px;
    width: 100px;
    cursor: url('../../public/images/cursor/pointer.cur'), auto;
}

.app-icon p {
    white-space: nowrap;
}

.app-icon:hover {
    outline: 1px dashed white;
}

.top-button {
    float: right; 
    height: 20px; 
    background-color: rgb(192, 199, 200);
    border-right-color: black;
    border-bottom-color: black;
    border-top-color: #FFF8FF;
    border-left-color: #FFF8FF;
    outline: 1px black solid;
    margin: 0 1px;
    cursor: url('../../public/images/cursor/pointer.cur'), auto;
}

.top-button:hover {
    filter: brightness(1.1);
}

.top-button:active {
    border-right-color: #FFF8FF;
    border-bottom-color: #FFF8FF;
    border-top-color: black;
    border-left-color: black;
    filter: brightness(0.9);
}

.hidden {
    display: none;
}

.slider[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 200px;
    height: 20px;
    background-color: #C0C7C8;
    border: none;
    outline: none;
}

.slider[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    border: inset 2px;
    cursor: url('../../public/images/cursor/pointer.cur'), auto;
    background-image: url('/public/images/pause.png');
    background-size: 8px;
}

.slider[type="range"]::-moz-range-track {
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    border: inset 2px;
    cursor: url('../../public/images/cursor/pointer.cur'), auto;
    background-image: url('/public/images/pause.png');
    background-size: 8px;
}

.slider[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: blue;
    border: 2px solid blue;
    border-radius: 0;
    cursor: url('../../public/images/cursor/pointer.cur'), auto;
}

.slider[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: blue;
    border: 2px solid blue;
    border-radius: 0;
    cursor: url('../../public/images/cursor/pointer.cur'), auto;
}

.slider[type="range"]::-webkit-slider-thumb:hover {
    border: 2px solid white;
}

.slider[type="range"]::-moz-range-thumb:hover {
    border: 2px solid white; /* environmental story telling */
}

.channelf-forum-container:nth-child(odd) {
    background-color: rgba(200, 200, 200, 0.2);
}

.channelf-forum {
    display: flex; 
    border: inset 1px; 
    color: white; 
    align-items: center; 
    gap: 20px;
    text-align: left;
}

.channelf-italic {
    opacity: 0.7; 
    margin-left: 5px;
}

.channelf-bold {
    color: lightblue;
    opacity: 0.7;
}

.app-drawer {
    background-color: #C0C7C8;
    outline: solid black 2px;
    display: flex;
    align-items: center;
    height: 25px;
    gap: 10px;
    cursor: url('../../public/images/cursor/pointer.cur'), auto;
}

.app-open {
    border: outset 3px;
    border-right-color: black;
    border-bottom-color: black;
    border-top-color: #FFF8FF;
    border-left-color: #FFF8FF;
}

.app-closed {
    border: inset 3px;
    border-right-color: #FFF8FF;
    border-bottom-color: #FFF8FF;
    border-top-color: darkgray;
    border-left-color: darkgray;
}

.app-open h2 {
    display: none;
}

.app-closed img {
    margin-left: 5px;
}

.hidestart {
    display: none;
}

.start {
    color: black;
    margin: 0;
    padding: 0;
    background-color: lightgray;
}

.start:hover {
    background-color: darkgray;
}

.button-on-bar {
    border: outset 2px; 
    width: 25px; 
    height: 25px; 
    padding: 0;
    background-color: lightgray;
    border-right-color: black;
    border-bottom-color: black;
    border-top-color: #FFF8FF;
    border-left-color: #FFF8FF;
    outline: 1px black solid;
    image-rendering: pixelated;
}

.button-on-bar:hover {
    filter: brightness(1.1);
}

.inboxblack p, .inboxblack h3, .inboxblack div {
    color: black;
}

.mailopen {
    background-color: blue;
}

.mailopen p, .mailopen h3, .mailopen div {
    color: white;
}

.taskbar-buttons {
    background-color: lightgray;
    outline: 1px black solid;
    height: 25px;
}

.taskbar-buttons:hover {
    filter: brightness(1.1);
}

.startmenu-link {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
}

.startmenu-link h2 {
    color: black;
}

.startmenu-link:hover {
    background-color: blue;
}

.startmenu-link:hover h2 {
    color: white;    
}

.window {
    z-index: 1;
}

.isactive {
    z-index: 2;
}

.error {
    z-index: 3;
}

.disabled {
    pointer-events: none;
    filter: contrast(0.5);
}

.back-a {
    cursor: url('../../public/images/cursor/blocked.cur'), auto;
}

.contextmenu {
    z-index: 10;
}

.isactive .active-toggle-bar {
    background-color: darkblue;
}

.isactive .active-toggle-text {
    color: white;
}

.active-toggle-bar {
    background-color: gray;
}

.active-toggle-text {
    color: lightgray;
}

.newwindow {
    z-index: 15;
}

.appwindow, .startmenu {
    border-top: white solid 3px;
    border-left: white solid 3px;
    border-bottom: black solid 3px;
    border-right: black solid 3px;
}

.text-overflow {
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
}