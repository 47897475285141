.btnnodecor {
    padding: 0;
    margin: 0 3px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.coinshop .precoinguy {
    color: black;
}

.precoinguy {
    font-family: monospace;
}

.precoinguy pre {
    color: 'black';
}