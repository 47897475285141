.App {
    display: block;
}

.MobileApp {
    display: none;
}

.home-grid {
    display: grid; 
    grid-template-columns: 20% 60% 20%;
    gap: 5px; 
    padding: 10px; 
    background: darkslategray;
    border: outset 3px;
    height: calc(100% - 27px);
}

.home-grid p {
    text-align: center;
}

.home-2 {
    grid-column: 1; 
    background: rgba(0,0,0,0.5); 
    padding: 3px; 
    border: inset 3px; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
}

.radio-grid {
    grid-template-columns: 33% calc(34% - 3px) calc(33% - 3px);
    gap: 3px
}

.user-radio-grid {
    grid-template-columns: 20% calc(20% - 3px) calc(20% - 3px) calc(20% - 3px) calc(20% - 3px);
    gap: 3px
}

.cyberpunk-radio {
    display: grid;
    grid-template-columns: 150px 70% 150px;
}

.cyberpunk-footer {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 30%) 10%; 
}

.channelf-albums {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px 5px black, 0 0 5px 5px black inset;
    grid-column: 1;
    grid-row: 1;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    padding: 0 0 20px 0;
}

.sunrise-grid {
    display: grid; 
    grid-template-columns: 15% 70% 15%; 
    border: solid 30px; 
    border-image-source: url(/public/images/sunrise/sun.gif); 
    border-image-repeat: repeat; 
    border-image-slice: 20 0;
}

.sunset-grid {
    display: grid; 
    grid-template-columns: 15% 70% 15%; 
    border: solid 30px; 
    border-image-source: url(/public/images/sunrise/star.gif); 
    border-image-repeat: repeat; 
    border-image-slice: 20 0;
}

.golden-breeze-upper {
    height: 100%;
}

.golden-breeze-lower {
    margin-top: 0;
}

.cortex-driver-lower {
    display: block;
}

.mobileshow {
    display: none;
}

.home-grid-container {
    margin-right: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
}

@container homegrid (max-width: 580px) {
    .home-grid-container {
        overflow-x: scroll;
    }

    .home-grid-container::-webkit-scrollbar {
        height: 15px;
    }

    .home-grid {
        width: 550px;
    }
}

@media screen and (max-width: 550px) {
    .App {
        display: none;
    }

    .MobileApp {
        display: block;
    }

    .mobilehide {
        display: none;
    }

    .mobileshow {
        display: block;
    }

    .home-grid {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        height: fit-content;
    }

    .radio-grid, .user-radio-grid {
        grid-template-columns: 50% calc(50% - 3px);
    }

    .audio-open.buttons-container {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 25px 25px;
        height: 55px;
        gap: 5px;
    }

    .cyberpunk-radio {
        display: block;
    }

    .cyberpunk-footer {
        display: block;
    }

    .channelf-albums {
        display: none;
    }

    .sunrise-grid, .sunset-grid {
        display: block;
        border: solid orange 3px;
    }

    .golden-breeze-upper {
        height: auto;
    }

    .golden-breeze-lower {
        margin-top: 150px;
    }

    .cortex-driver-lower {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .fish-mobile {
        scale: 0.73;
    }

    .moemoe-mobile {
        height: 580px;
    }
}