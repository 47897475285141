.play-button-casino {
    cursor: url('/public/images/cursor/pointer.cur'), auto;
}

.play-button-casino:hover {
    filter: brightness(1.1);
}

.play-button-casino:active {
    filter: brightness(0.9);
}

.machine-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}