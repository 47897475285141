
.player-button {
    display: flex; 
    align-items: center; 
    border: outset 3px; 
    background-image: url('../../../public/images/button.png'); 
    background-size: contain; 
    cursor: url('../../..//public/images/cursor/pointer.cur'), auto;
}

.indent {
    border: inset 3px;
    filter: brightness(0.8);
}

.player-open {
    display: 'grid';
}

.audio-closed {
    display: none;
}

.audio-open {
    display: flex;
}

.i-button {
    margin: 15px; 
    display: inline-flex; 
    justify-content: center; 
    align-items: center; 
    border: outset 3px;
}

@media screen and (max-width: 550px) {
    .i-button {
        display: none;
    }
}

.scrolling {
    overflow: hidden;
    animation: text-scroll 15s 2s infinite linear forwards;
}

@keyframes text-scroll {
    0% {
        transform: translateX(200%);
        opacity: 0;
    } 10% {
        transform: translateX(15%);
        opacity: 0.5;
    } 12% {
        opacity: 1;
    } 90% {
        transform: translateX(-50%);
        opacity: 1;
    } 92% { 
        opacity: 0.5;
    } 100% {
        transform: translateX(-200%);
        opacity: 0;
    }
}