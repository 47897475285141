.ch-profile p {
    color: rgb(255, 255, 204);
    font-size: 16px;
}

.pp-sm {
    text-align: center;
}

.pp-sm p, .pp-sm h1, .pp-sm h2, .pp-sm h3 {
    font-family: Arial, Helvetica, sans-serif;
    text-shadow: 0 0 3px black;
}