.pinky p {
    font-family: MS UI Gothic;
    font-size: 8pt;
    color: #ff9999;
}

.pinkydiv {
    padding: 8px; 
    background: white; 
    width: 40%; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    border: dashed pink 4px; 
    border-radius: 25px;
}